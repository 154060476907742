import autosize from 'autosize';
import Choices from 'choices.js';
import flatpickr from 'flatpickr';


const setupForms = (rootEl) => {
  autosize(rootEl.querySelectorAll('textarea'));

  rootEl.querySelectorAll('select').forEach(el => {
    el._choices_js = new Choices(el, {
      itemSelectText: '',
      position: 'below',
    });
  });

  rootEl.querySelectorAll('input[data-type="date"]').forEach(el => {
    el._flatpickr = flatpickr(el, {
      locale: {firstDayOfWeek: 1},
    });
  });

  rootEl.querySelectorAll('input[data-type="datetime"]').forEach(el => {
    el._flatpickr = flatpickr(el, {
      enableTime: true,
      time_24hr: true,
      locale: {firstDayOfWeek: 1},
    });
  });
};

const destroyForms = (rootEl) => {
  autosize.destroy(rootEl.querySelectorAll('textarea'));

  rootEl.querySelectorAll('select').forEach(el => {
    if (el._choices_js) {
      el._choices_js.destroy();
    }
  });

  rootEl.querySelectorAll('input[data-type="date"], input[data-type="datetime"]').forEach(el => {
    if (el._flatpickr) {
      el._flatpickr.destroy();
    }
  });
};

const setupFastview = () => {
  // Handle fastview fieldsets
  document.addEventListener('fastview-formset-createForm', e => {
    setupForms(e.detail.form.rootEl);
  });
  document.addEventListener('fastview-formset-destroyForm', e => {
    destroyForms(e.detail.form.rootEl);
  });
};

export const setupComponents = () => {
  // Set up forms on every page except powerwiki
  setupForms(document.querySelector('body:not([class^="powerwiki_"])'));

  // Destroy any template forms - we weren't supposed to initialise those
  const formsetTemplates = document.querySelectorAll(
    'fieldset[data-fastview-formset-template]'
  );
  formsetTemplates.forEach(template => {
    destroyForms(template);
  });

  // Listen to fastview events
  setupFastview();
};
