
import { setupUtils } from './utils';
import { setupComponents } from './components';
import { setupApps } from './apps';

import '../../../venv/src/django-fastview/static_src/index';

document.addEventListener("DOMContentLoaded", function() {
  setupUtils();
  setupComponents();
  setupApps();
})
